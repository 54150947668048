import React, { useMemo } from 'react';
import { ResponsiveGrid } from '@adobe/aem-react-editable-components';
import { ApolloProvider } from '@apollo/client';
import { getDeployedEnvType, useNextMiApolloClient } from '@marriott/mi-apollo-client-utils';
import { Layout, respGridUtil, prePageCall } from '@marriott/mi-headless-utils';
import { constants, IMAGE_URL_DOMAIN, PAGES } from '@marriott/mi-rnb-components/constants';
import { GetServerSideProps } from 'next';
import { PageParamsContext } from '@marriott/mi-rnb-components';
import operationSignatures from '../../_constants/operationSignatures.json';

const { NEXT_PUBLIC_AEM_PATH } = process.env;
const { ENV_DEV } = process.env;
const isDev = process.env['NODE_ENV'] === ENV_DEV;

const mockModel = async () => {
  if (isDev) {
    // nx-ignore-next-line
    const { homePageModel } = await import('@marriott/mi-rnb-components/localModel');
    return homePageModel;
  }
  return {};
};

const parseFeatureConfig = featureName => {
  const value = process.env[featureName];
  if (!value) {
    return [];
  }
  return value.split('||').map(s => s.trim());
};

const identifyRegion = countryCode => {
  // Parse regions
  const regions = parseFeatureConfig('FEATURE_HOMEPAGE_VARIATION_REGIONS');
  // Check each region for the country code
  for (const region of regions) {
    const key = `FEATURE_HOMEPAGE_VARIATION_${region.toUpperCase()}`;
    const countries = parseFeatureConfig(key);
    if (countries.includes(countryCode)) {
      return region; // Return the region if a match is found
    }
  }
  return '';
};

const { ITEM_PATH_ROOT, ITEM_PATH_FOOTER, ITEM_PATH_HEADER } = constants;
const { HOMEPAGE } = PAGES;

// final data would have to come from 2 calls:
// 1. Page level call from AEM - that will populate the labels on components
// 2. Functional data for components like list of amenities and other related information
// import jsonContent from '../../mock/label-info.json';

export default function HomePage({ model, deployedEnvType, isAuthorMode, cookies }) {
  const MiApolloClient = useNextMiApolloClient(operationSignatures, deployedEnvType);

  const responsiveGridModel = respGridUtil(model?.cqItems?.root?.[':items']?.responsivegrid);
  const responsiveGridHeaderModel = respGridUtil(model?.cqItems?.root?.[':items']?.['header-responsivegrid']);
  const responsiveGridFooterModel = respGridUtil(model?.cqItems?.root?.[':items']?.['footer-responsivegrid']);

  const {
    metaNames,
    metaProperties,
    canonical,
    title,
    staticComponentClientLibsJS,
    staticComponentClientLibsCSS,
    gridCss,
    bgColor,
    hrefLangList,
  } = model;

  const contextValue = useMemo(
    () => ({ userId: cookies.UserIdToken, currentPage: HOMEPAGE, backgroundColor: bgColor }),
    [bgColor, cookies.UserIdToken]
  );

  //  Updating the meta properties and title as per SEO requirements
  const metaProperty = metaProperties;

  const updateMetaProperties = () => {
    const urlKey = metaNames?.find(item => item?.name === 'url');
    const titleKey = metaNames?.find(item => item?.name === 'title');

    metaProperty?.map(item => {
      if (item?.name === 'og:url' && !item?.value) item.value = urlKey?.value;
    });

    metaProperty?.map(item => {
      if (item?.name === 'og:title' && !item?.value) item.value = titleKey?.value;
    });

    metaProperty?.map(item => {
      if (item?.name === 'og:image') {
        if (!item.value.includes(IMAGE_URL_DOMAIN)) {
          const imgUrl = IMAGE_URL_DOMAIN + item.value;
          item.value = imgUrl;
        }
      }
    });
  };

  updateMetaProperties();
  return (
    <ApolloProvider client={MiApolloClient}>
      <PageParamsContext.Provider value={contextValue}>
        <Layout
          metaNames={metaNames}
          metaProps={metaProperty}
          title={title}
          staticComponentClientLibsCSS={staticComponentClientLibsCSS}
          staticComponentClientLibsJS={staticComponentClientLibsJS}
          isAuthorMode={isAuthorMode}
          gridCss={gridCss}
          canonical={canonical}
          hreflangList={hrefLangList}
        >
          <section>
            {responsiveGridHeaderModel && (
              <ResponsiveGrid
                {...responsiveGridHeaderModel}
                model={responsiveGridHeaderModel}
                pagePath={NEXT_PUBLIC_AEM_PATH}
                itemPath={ITEM_PATH_HEADER}
              />
            )}

            <ResponsiveGrid
              {...responsiveGridModel}
              model={responsiveGridModel}
              pagePath={NEXT_PUBLIC_AEM_PATH}
              itemPath={ITEM_PATH_ROOT}
            />

            {responsiveGridFooterModel && (
              <ResponsiveGrid
                {...responsiveGridFooterModel}
                model={responsiveGridFooterModel}
                pagePath={NEXT_PUBLIC_AEM_PATH}
                itemPath={ITEM_PATH_FOOTER}
              />
            )}
          </section>
        </Layout>
      </PageParamsContext.Provider>
    </ApolloProvider>
  );
}

const removeQueryParam = (url: string) => {
  return url.includes('?') ? url.split('?')[0] : url;
};

//Any product specific page data apart from common data like session, datalayer
//can be returned from this function and the same is passed as props to the page Component
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rnbSpecificData = async (context: { [key: string]: any }) => {
  //any page specific code can be written here.
  const deployedEnvType = getDeployedEnvType();

  const resolvedUrl = context?.resolvedUrl;
  const currentLocale = context?.locale;
  const countryInfo = context?.req?.headers['x-akamai-edgescape']?.match(/country_code=([A-Za-z]{2})/);
  const countryCode = countryInfo?.length && countryInfo[1];
  const region = countryCode ? identifyRegion(countryCode).toLowerCase() : '';
  const resolvedUrlWithoutParams = removeQueryParam(resolvedUrl);
  const uri =
    !!countryCode && currentLocale === 'en-US' && !!region
      ? resolvedUrlWithoutParams + `/home/${region}`
      : resolvedUrlWithoutParams;
  return {
    operationSignatures: operationSignatures,
    deployedEnvType,
    uri: uri,
  };
};

//Make pre page calls like session, datalayer, fetch model.json, producSpecifc data etc
//at server side and pass the data is passed as props to page component.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getServerSideProps: GetServerSideProps<any> = prePageCall(rnbSpecificData, mockModel, false, true);
